import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import VueCropper from 'vue-cropper'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/icons/iconfont.css'
import  '@/utils/WxLogin.js'
Vue.config.productionTip = false
// 弹出框的时候滚动条隐藏和出现导致页面抖动问题
ElementUI.Dialog.props.lockScroll.default = false;
import { SmartRem } from "./utils"
SmartRem.install();
/**
 * 解决遮罩层黑屏
 */
 const resetModalStyle = () => {
  setTimeout(() => {
    const vmodal = document.body.getElementsByClassName('v-modal')
    if (vmodal.length) {
      vmodal[0].style.opacity = 1
      vmodal[0].style.background = 'rgba(0,0,0,0.5)'
    }
  })
}
const dialogWatch = ElementUI.Dialog.watch.visible
ElementUI.Dialog.watch.visible = function() {
  return new Promise(resolve => {
    resetModalStyle()
    resolve(dialogWatch.apply(this, arguments))
  })
}
const MessageConfirm = ElementUI.MessageBox.confirm
ElementUI.MessageBox.confirm = function() {
  return new Promise(resolve => {
    resetModalStyle()
    resolve(MessageConfirm.apply(this, arguments))
  })
}
//弹出框禁止滑动
Vue.prototype.stopScroll = function () {
  var mo = function (e) { e.preventDefault() }
  document.body.style.overflow = 'hidden'
  document.addEventListener('touchmove', mo, false)// 禁止页面滑动
}
 
//弹出框可以滑动
Vue.prototype.canScroll = function () {
  var mo = function (e) {
    e.preventDefault()
  }
  document.body.style.overflow = ''// 出现滚动条
  document.removeEventListener('touchmove', mo, false)
}
Vue.use(ElementUI)
Vue.use(VueCropper)
new Vue({
  router,
  beforeCreate() {
    Vue.prototype.$bus = this //事件总线
  },
  render: h => h(App)

}).$mount('#app')