<template>
	<div style="overflow-x: hidden;">
		<div>
			<NavMenu/>
		</div>
		<div class="centerWarp">
			<img src="@/assets/imgs/train/topimg.png" class="topimg">
			<div class="breadBox">
				<img src="@/assets/imgs/train/icon1.png" class="homeicon" @click="goHomeFn">
				<div class="breadText">当前位置：
					<span>相关资讯</span>
				</div>
			</div>
			<div class="dataWarp">
				<div class="linebox">相关资讯</div>
				<div class="moreList">
					<div v-for="(item,i) in listData" :key="i" class="columnlistItem" @click="goNewsDetail(item.pressId)">
						<img :src="srcUrl+item.titleImgUrl" class="newImg">
						<div class="rightContent">
							<!-- <div class="type-title "> -->
							<!-- <div class="type">【{{item.type}}】</div> -->
							<div class="title overflowtwo">{{item.pressTopic}}</div>
							<!-- </div> -->
							<div class="content overflowtwo">{{item.synopsis}}</div>
							<div class="date-source">
								<div class="date">{{item.pressTime | timer}}</div>
								<!-- <div class="source">来源:{{item.pressSource}}</div> -->
							</div>
						</div>
					</div>
				</div>

				<div class="pagination-box">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
					    :page-sizes="[5,10,20,30]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
					</el-pagination>
				</div>
		
				<div v-infinite-scroll="load" infinite-scroll-disabled="disabled" infinite-scroll-distance="20" class="mobiledataWarp">
					<div v-for="(item,index1) in dataList" :key="index1" track-by="$index" class="columnlistItem" @click="goNewsDetail(item.pressId)">
						<img :src="srcUrl+item.titleImgUrl" class="newImg">
						<div class="rightContent">

							<div class="title overflowtwo">{{item.pressTopic}}</div>
							<!-- </div> -->
							<div class="content overflowtwo">{{item.synopsis}}</div>
							<div class="date-source">
								<div class="date">{{item.pressTime | timer}}</div>
								<!-- <div class="source">来源:{{item.pressSource}}</div> -->
							</div>
						</div>
					</div>
					<!-- //下拉加载时候加loading旋转动效 -->
					<div v-if="loading" style="margin-top:10px;" class="loading">
						<span></span>
					</div>
					<!-- //加载完成，提示语句 -->
					<div v-if="noMore" style="margin:.2667rem auto; text-align: center; font-size:.32rem;font-weight: 400; color:#333333">----没有更多了----</div>
				</div>

			</div>
			<!-- 移动端展示 -->

		</div>
		<div class="footerbox">
			<Footer/>
		</div>
	</div>
</template>
<script>
import NavMenu from '@/components/navMenu.vue'
import Footer from '@/components/footer.vue'
import { httpService, srcUrl } from "@/service/httpService";
export default {
	name: "MoreNews",
	components: {
		NavMenu,
		Footer,
	},
	data() {
		return {
			srcUrl: srcUrl,
			listData: [],
			currentPage: 1,//滚动加载默认显示第一页 pages
			total: 0,
			pageSize: 10,//当前分页条数 pageIndex
			pageIndex: 1,//zongtiaoshu
			loading: false,  //加载时的动画
			dataList: [],
		}
	},
	computed: {
		noMore() {
			//当起始页数大于总页数时停止加载
			return this.pageIndex >= this.currentPage;
		},
		disabled() {
			return this.loading || this.noMore;
		}
	},
	mounted() {
		this.goNewsFn()
		this.getList()
	},
	methods: {
		async goNewsFn() {
			let params = {
				pageSize: this.pageSize,
				pageCurrent: this.currentPage,
			};
			let data = await httpService.getNewsList(params);
			if (data.code == 200) {
				this.listData = data.result.list
				this.total = data.result.total;
			}
		},
		async getList() {
			let obj = {
				pageCurrent: 1,   //此参数一定要确认好，传错肯定运行效果出错
				pageSize: 30, //每页查询的条数
			}
			let data = await httpService.getNewsList(obj);
			if (data.code == 200) {
				let pageInfo = data.result
				pageInfo.list.forEach((el) => {
					this.dataList.push(el)
				})
				// console.log(this.dataList,'this.dataList');
				this.pages = data.pages
				this.loading = false;
			}
		},
		load() {
			//滑到底部时进行加载
			this.loading = true;
			setTimeout(() => {
				let obj = {
					pageCurrent: this.pageIndex + 1,   //pageIndex此参数根据自己实际情况，分页所需参数有的接口不一样
					pageSize: 50, //每页查询的条数
				}

				httpService.getNewsList(obj)
					.then((res) => {
						let pageInfo = res.result
						pageInfo.list.forEach(e => {
							this.dataList.push(e)
						})
						this.pageIndex = pageInfo.pageNum
						this.loading = false;
					})
					.catch((error) => {
						console.log(error)
					})
			}, 1500);
		},
		handleSizeChange(val) {
			this.pageSize = val;
			this.currentPage = 1;
		},
		handleCurrentChange(val) {
			this.currentPage = val;
		},
		goNewsDetail(val) {
			this.$router.push({
				path: "/news-detail",
				query: {
					id: val,
				},
			});
		},
		goHomeFn() {
			this.$router.push('/');
			// sessionStorage.setItem('clickMenu', '首页')
		}
	},
	filters: {
		timer: (value) => {
			return value.substring(0, 10);
		},
	},
}
</script>
<style lang="less" scoped>
.centerWarp {
	width: 100%;
	position: relative;
	// overflow-x: hidden;
	.topimg {
		width: 100%;
		height: 320px;
	}
	.breadBox {
		position: absolute;
		top: 320px;
		width: 100%;
		height: 60px;
		background: #f6f6f6;
		display: flex;
		flex-direction: row;
		padding-left: 280px;
		box-sizing: border-box;
		align-items: center;
		.homeicon {
			width: 20px;
			height: 18px;
			margin-right: 8px;
			cursor: pointer;
		}
		.breadText {
			font-size: 16px;
			font-family: Source Han Sans CN-Regular, Source Han Sans CN;
			font-weight: 400;
			color: #737373;
			span {
				// cursor: pointer;
				// &:hover {
				// 	color: #d31114;
				// }
			}
		}
	}
	.dataWarp {
		margin-top: 30px;
		width: 100%;
		padding: 42px 280px 46px 280px;
		box-sizing: border-box;
		
		.linebox {
			font-size: 30px;
			font-family: Source Han Sans CN-Bold, Source Han Sans CN;
			font-weight: bold;
			color: #333333;
			line-height: 36px;
			padding-bottom: 24px;
			border-bottom: 1px solid #d3d3d3;
			position: relative;
			&::before {
				content: '';
				position: absolute;
				width: 52px;
				height: 0px;
				border: 2px solid #d31114;
				opacity: 1;
				background: #d31114;
				left: 0;
				transform: translateX(-0%);
				top: 57px;
			}
		}
		.moreList {
			width: 100%;
			justify-content: center;
			// width: 7.0833rem;
			display: block;
			padding-top: 44px;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
		}
		.mobiledataWarp {
			display: none;
		}
	}
}
@media screen and (max-width: 600px) {
	.centerWarp {
		width: 100%;
		position: relative;
		.topimg {
			width: 100%;
			height: 2.4rem;
		}
		.breadBox {
			position: absolute;
			top: 2.24rem;
			width: 100%;
			height: 0.8rem;
			background: #f6f6f6;
			display: flex;
			flex-direction: row;
			padding-left: 0.4rem;
			box-sizing: border-box;
			align-items: center;
			.homeicon {
				width: 0.5333rem;
				height: 0.48rem;
				margin-right: 0.2133rem;
				cursor: pointer;
			}
			.breadText {
				font-size: 0.4267rem;
				font-family: Source Han Sans CN-Regular, Source Han Sans CN;
				font-weight: 400;
				color: #737373;
				span {
					// cursor: pointer;
					// &:hover {
					// 	color: #d31114;
					// }
				}
			}
		}
		.dataWarp {
			margin-top: 0;
			width: 100%;
			padding: 1.0667rem 0.4rem;
			box-sizing: border-box;
			.linebox {
				font-size: 0.5333rem;
				font-family: Source Han Sans CN-Bold, Source Han Sans CN;
				font-weight: bold;
				color: #333333;
				line-height: 0.8rem;
				padding-bottom: 0.24rem;
				border-bottom: 1px solid #d3d3d3;
				position: relative;
				&::before {
					content: '';
					position: absolute;
					width: 1.1rem;
					height: 0px;
					border: 2px solid #d31114;
					opacity: 1;
					background: #d31114;
					left: 0;
					transform: translateX(-0%);
					top: 0.9rem;
				}
			}
			.moreList {
				// width: 7.0833rem;
				display: none;
			}
			.mobiledataWarp {
				display: block;
				padding-top: 0.6933rem;
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
				p {
					font-size: 0.3733rem;
					text-align: center;
				}
				.loading {
					text-align: center;
				}
				.loading span {
					display: inline-block;
					width: 35px;
					margin: 0 auto;
					height: 35px;
					border: 3px solid #409eff;
					border-left: transparent;
					animation: zhuan 0.5s linear infinite;
					border-radius: 50%;
				}
				@keyframes zhuan {
					0% {
						transform: rotate(0);
					}
					100% {
						transform: rotate(360deg);
					}
				}
			}
		}
	}
}
</style>