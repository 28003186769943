<template>
	<div>
		<div class="footer-warp">
			<div class="footerCenter">
				<div class="leftBox">
					<img src="@/assets/imgs/home/code.png" class="codeImg" />
					<div class="textBox">
						<!-- <div>扫码关注： </div>
					<div class="companyNmae">四川企业管理有限公司微信公众号</div> -->
						<!-- <div>
						扫码关注：
					</div> -->
						<div>扫码关注：高管能力提升课程</div>
					</div>
				</div>
				<div class="line"></div>
				<div @click="link('https://appbxqvuncv7616.h5.xiaoeknow.com/')" style="cursor: pointer">HRS云课堂</div>
				<div class="line"></div>
				<img src="@/assets/imgs/home/logo2.png" class="logoImg" @click="link('http://www.zgrcjlxh.com/')" />
				<!-- <div class="linkbox">
				<div class="linkimg">
					<i class="el-icon-link" /> 友情链接:
				</div>
				<div class="link-Box">
					<div v-for="(item,index) in rowlinkList" :key='index' class="jumplinkbox">
						<div v-if="item.name=='HRS在线课堂'">{{item.name}}</div>
						<div v-else><a :href='item.linkurl' target="_blank" >{{item.name}}</a></div>
					</div>
				</div>
				<div v-for="(item,index) in linkList" :key='index' class="jumplinkbox2">
					<a :href='item.linkurl' target="_blank">{{item.name}}</a>
				</div>
			</div> -->
			</div>
			<div class="footerbotbox">
				<!-- <div class="linebox">
				<div class="line"></div>
				<div class="line"></div>
			</div> -->
				<div class="sponsorbox">备案号:<a href="https://beian.miit.gov.cn/ " target="_blank">蜀ICP备2022027197号-1</a></div>
				<!-- <div>备案号：ICP备案：</div> -->
			</div>
		</div>
		<div class="mobileImg">
			<img src="../assets/imgs/mobile/4.png" alt="" />
			<div class="botbox">
				<div @click="link('https://appbxqvuncv7616.h5.xiaoeknow.com/')"></div>
				<div @click="link('http://www.zgrcjlxh.com/')"></div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {

			activeName: '',
			linkList: [

				{
					name: '能力指数测评',
					linkurl: 'http://www.zgrcjlxh.com/assessment-institutions'
				},
				{
					name: '培训成绩查询',
					linkurl: 'http://www.zgrcjlxh.com/train-service'
				},
			],
			rowlinkList: [
				{
					name: '中国人才交流协会',
					linkurl: 'http://www.zgrcjlxh.com/'
				},

				{
					name: 'HRS在线课堂',
					linkurl: ''
				},
			],
			// abousList: [
			// 	{					name: '联系我们', icon: ''), 
			// 	data: [
			// 			{ title: '办公室：010-88824565' },
			// 			{ title: '发展部：010-88824569' },
			// 			{ title: '会员部：010-68709272' },
			// 			{ title: '信息部：010-68709390' },
			// 		]				},
			// 	{ name: '意见邮箱：xhmsc@zgrcjlxh.com', icon: require('@/assets/imgs/home/icon3.png') },
			// 	{ name: '扫描二维码 立即关注', icon: require('@/assets/imgs/home/icon4.png'), img: require('@/assets/imgs/home/icon5.png'), },
			// ]
		};
	},

	methods: {
		gopageFn(item) {
			sessionStorage.setItem('clickMenu', item.name)
			this.$router.push({
				path: item.linkUrl,
			});
		},
		link(val) {
			window.open(val)
		}


	},
};
</script>
<style lang="less" scoped>
.mobileImg {
	display: none;
}
.footer-warp {
	width: 100%;
	display: flex;
	flex-direction: column;
	background: #232222;

	.footerCenter {
		width: 900px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		font-size: 16px;
		font-family: Source Han Sans CN-Regular, Source Han Sans CN;
		font-weight: 400;
		color: #ffffff;
		align-content: center;
		align-items: center;
		padding: 14px 0 30px 0;
		margin-right: 730px;
		font-size: 14px;
		.line {
			width: 0px;
			height: 22px;
			opacity: 0.38;
			border: 1px solid #ffffff;
		}
		.logoImg {
			width: 155px;
			height: 35px;
			cursor: pointer;
		}
		.linkbox {
			display: flex;
			flex-direction: column;
			.linkimg {
				font-size: 14px;
				color: #ffffff;
				margin-bottom: 15px;
			}
			.link-Box {
				display: flex;
				flex-direction: row;
			}
			.jumplinkbox {
				a {
					font-size: 16px;
					line-height: 30px;
					color: #ffffff;
					&:hover {
						color: #fff;
					}
				}
				&:last-child {
					margin-left: 0.3333rem;
					a {
						border: none;
					}
				}
				div {
					font-size: 16px;
					line-height: 40px;
					color: #ffffff;
					cursor: pointer;
					&:hover {
						color: #fff;
					}
				}
			}
			.jumplinkbox:hover {
				color: #fff;
			}
			.jumplinkbox2 {
				// height: 30px;
				a {
					font-size: 16px;
					line-height: 30px;
					color: rgba(255, 255, 255, 0.74);
					&:hover {
						color: #fff;
					}
				}
				&:last-child {
					a {
						border: none;
					}
				}
			}
		}
		.leftBox {
			display: flex;
			flex-direction: row;
			padding-top: 22px;
			.textBox {
				font-size: 14px;
				line-height: 24px;
				color: #fff;
				text-align: center;
				padding: 40px 10px 48px 18px;
				// padding-top: 80px;
				.companyNmae {
					margin-bottom: 30px;
				}
			}
			.codeImg {
				width: 123px;
				height: 123px;
			}
		}
	}
	.footerbotbox {
		background: #101010;
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 10px 0 20px 0;
		font-size: 16px;
		.linebox {
			display: flex;
			flex-direction: row;
			.line {
				margin: 0 24px;
				width: 654px;
				border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
			}
		}
		.sponsorbox {
			font-size: 14px;
			line-height: 40px;
			color: #a2a3ae;
			font-weight: 300;
			a{
				color: #a2a3ae;
			}
		}
	}
}
@media screen and (max-width: 600px) {
	.footer-warp {
		display: none;
	}

	.mobileImg {
		display: block;
		width: 100%;
		background: #232222;
		position: relative;
		.botbox {
			width: 100%;
			height: 20px;
			// background: red;
			position: absolute;
			bottom: 1.44rem;
			left: 0px;
			display: flex;
			flex-direction: row;
			div {
				width: 50%;
			}
		}
		img {
			width: 100%;
		}
	}
}
</style>

