<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { httpService } from "./service/httpService.js";
export default {
  data() {
    return {};
  },

  mounted() {

    // this.changeGray();
    window.addEventListener("resize", () => {
      document.body.style.zoom = 1 / window.devicePixelRatio;
    });
    const keyCodeMap = {
      // 91: true, // command
      61: true,
      107: true, // 数字键盘 +
      109: true, // 数字键盘 -
      173: true, // 火狐 - 号
      187: true, // +
      189: true, // -
    };
    // 覆盖ctrl||command + ‘+’/‘-’
    document.onkeydown = function (event) {
      const e = event || window.event;
      const ctrlKey = e.ctrlKey || e.metaKey;
      if (ctrlKey && keyCodeMap[e.keyCode]) {
        e.preventDefault();
      } else if (e.detail) { // Firefox
        event.returnValue = false;
      }
    };
    // 覆盖鼠标滑动
    document.body.addEventListener('wheel', (e) => {
      if (e.ctrlKey) {
        if (e.deltaY < 0) {
          e.preventDefault();
          return false;
        }
        if (e.deltaY > 0) {
          e.preventDefault();
          return false;
        }
      }
    }, { passive: false });
  },

  //  切换灰色背景
  methods: {
    // async changeGray() {
    //   let dom = document.documentElement;
    //   let res = await httpService.getGray();
    //   if (res.code == 200) {
    //     if (res.result.webColorStatus) {
    //       dom.classList.add("gray");
    //     } else {
    //       dom.classList.remove("gray");
    //     }
    //   }
    // },
  },
};
</script>
<style lang="less">
@import './assets/css/common.less';
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// p {
	// 	margin-block-end: 0;
	// 	margin-block-start: 0;
	// }

	/deep/.el-popup-parent--hidden {
		overflow: auto;
	}
	a {
		text-decoration: none;
	}
	.fade-leave-to {
		display: none;
	}
	// [v-clock] {
	// 	display: none!important;
	// }
}
</style>
