<template>
	<div class="navMenuWarp">
		<div class="navCenter">
			<img src='@/assets/imgs/home/logo.png' class="logoImg" />
			<!-- <div class="title">义度有限公司官网</div> -->
			<!-- <div class="nav-menubox">
        <div class="menuWarp">
          <div :class="{
            'currentMenu-box':
              currentMenu == item.title || hoverMenu == item.title,
          }" class="menu-box" v-for="item in menuData" :key="item.id" @click="clickMenuFn($event, item)">
            <div class="menu-name" :data-logo="item.logo">
              <img :src='item.imgurl' v-if="item.imgurl" />
              <div>{{ item.title }}</div>
              <div class="trianglebox"></div>
            </div>
          </div>
        </div>
      </div> -->
			<!-- <div class="search-box">
		<el-input type="text" v-model="searchVal" placeholder="请输入搜索内容" @keydown.enter.native="serchEnterFn" class="input-with-select">
            <el-button slot="append" icon="el-icon-search" @click="btnsearch"></el-button>
          </el-input>
      </div> -->
			<!-- <div class="navRight">
        <div class="hrsText"> <img src='@/assets/imgs/home/icon1.png' class="hrsicon" />
          <span>HRS高管能力提升课程</span>
        </div>
        <div class="loginBtn" @click="loginFn">登录</div>
      </div> -->
		</div>
		<div class="mobileNav">
			<img src="../assets/imgs/mobile/2.png" alt="" />
		</div>
	</div>
</template>
<script >
import { httpService, srcUrl } from "@/service/httpService";
export default {
	props: {
		fatherMenu: String,
	},
	data() {
		return {
			srcUrl: srcUrl,
			menuData: [
				{ title: "首页", linkUrl: "/", },
				{
					title: "培训",
					linkUrl: "/train",
				},
				{
					title: "会议",
					linkUrl: "/meet",

				},
				{
					title: "咨询",
					linkUrl: "/consult",
				},
			],
			currentMenu: "首页",
			// 搜索
			searchFlag: false,
			searchVal: "",
			hoverMenu: "",
		};
	},

	beforeMount() {
		let clickMenu = sessionStorage.getItem("clickMenu");
		this.currentMenu = clickMenu;
	},

	methods: {

		clickMenuFn(e, val) {
			// 清楚二级页面点击菜单
			sessionStorage.removeItem('currentNew')
			sessionStorage.removeItem("site");
			if (e.target.nodeName == "DIV" || e.target.dataset.logo == "one") {
				// 清除子菜单
				sessionStorage.setItem("clickMenu", val.title);
				sessionStorage.setItem("fatherlinkUrl", val.linkUrl);
				this.currentMenu = val.title;
				this.$router.push({
					path: val.linkUrl,
				});
			}
		},
		searchFn() {
			if (this.searchVal) {
				sessionStorage.setItem("searchVal", this.searchVal);
				let clickMenu = sessionStorage.getItem('clickMenu')
				if (clickMenu != '首页') {
					this.currentMenu = "首页";
					sessionStorage.setItem("clickMenu", "首页");
				}
				// this.$router.push({name: "SearchPage"});
				this.$router.push({
					path: '/search',
					query: {
						val: this.searchVal
					}

				});
				this.searchVal = null
			} else {
				// this.$message('请输入搜索内容')
				this.searchFlag = false;
			}
		},
		serchEnterFn(e) {
			let keyCode = window.event ? e.keyCode : e.which;
			if (keyCode == 13) {
				this.searchFn();
			}
		},
		btnsearch() {
			this.searchFn();
		},
		loginFn() {
			this.$message('功能开发中，敬请期待！')
		},
	},
};
</script>
<style scoped lang="less">
.navMenuWarp {
	width: 100%;
	margin: 0 auto;
	background: #fff;
	.mobileNav {
		display: none;
	}
	.navCenter {
		width: 1360px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		height: 70px;
		box-sizing: border-box;
		.logoImg {
			width: 298px;
			height: 53px;
		}
		// .title{
		// 	font-size: 24px;
		// 				font-family: Source Han Sans CN-Bold, Source Han Sans CN;
		// 				font-weight: bold;
		// 				color: #333333;
		// 				line-height: 60px;
		// }
		// 搜索框
		.search-box {
			// background: #1088fe;
			width: 225px;
			justify-content: center;
			align-items: center;
			align-content: center;
			text-align: center;
			height: 40px;
			display: flex;
			flex-direction: row;
			margin-right: 36px;
			position: relative;
			/deep/.el-input__inner {
				// background-color: #68b4ff;
				border: none;
				background: #efefef;
				border-radius: 0px;
			}
			// /deep/.el-icon-search {
			// 	color: #D31114;
			// 	font-size: 22px;
			// }
			/deep/.el-icon-search {
				color: #fff;
			}
			/deep/.el-button {
				height: 40px;
				background: #d31114;
				// border: 1px solid #1875d9;
				border-radius: 0px;
			}
			/deep/.el-input-group__append,
			.el-input-group__prepend {
				padding: 0;
			}
		}
		.navRight {
			display: flex;
			flex-direction: row;
			align-items: center;
			.hrsText {
				display: flex;
				flex-direction: row;
				font-size: 0.0938rem;
				color: #333333;
				margin-right: 30px;
				cursor: pointer;
				// width: .7917rem;
				.hrsicon {
					width: 0.125rem;
					// height: .125rem;
				}
			}
			.loginBtn {
				width: 0.3958rem;
				height: 0.1979rem;
				background: #d31114;
				border-radius: 0.0208rem;
				color: #fff;
				text-align: center;
				line-height: 0.1979rem;
			}
		}
	}
	.nav-menubox {
		// width: 4rem;
		box-sizing: border-box;
		margin: 0 auto;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		color: #666666;
		font-size: 0.1042rem;
		font-weight: 500;
		position: relative;
		// border-right: 5px solid #d60029;
		.menuWarp {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			align-content: center;
		}
		.menu-box:hover {
			// color: #1088fe;
			.trianglebox  {
				display: block;
			}
		}
		.menu-box {
			color: #666666;
			text-align: center;
			margin: 0 30px;
			height: 0.25rem;
			line-height: 0.25rem;
			// position: relative;
			// min-width: 40px;
			position: relative;
			padding: 0 6px;
			&:first-child {
				min-width: 0.25rem;
				margin-left: 0;
			}
			.menu-name {
				// width: 6vw;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				// margin: 0 20px 0 20px;
				cursor: pointer;
				position: relative;
				img {
					width: 26px;
					height: 30px;
				}
			}
			.trianglebox  {
				position: absolute;
				bottom: -14px;
				left: 50%;
				transform: translateX(-50%);
				width: 36px;
				height: 2px;
				background: #d31114;
				display: none;
			}
		}
		// 点击之后的样式
		.currentMenu-box {
			// background: #d60029;
			color: #d31114;
			padding: 0 6px;
			cursor: pointer;
			.trianglebox  {
				display: block;
			}
			.menu-name {
				cursor: pointer;
			}
		}
	}
}
@media screen and (max-width: 600px) {
	.navMenuWarp {
		.navCenter {
			display: none;
		}
		.mobileNav {
			display: block;
			width: 10rem;
			box-sizing: border-box;
			img {
				width: 4.4rem;
				height: .75rem;
				padding: .2933rem 0 .24rem .4533rem
			}
		}
	}
}
</style>