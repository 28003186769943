import {
    SmartHttp
} from '@/utils'

// let urls = publicMethod.getUrls();
const request = new SmartHttp({
    baseURL: process.env.VUE_APP_GATEWAY
}).request;
export const srcUrl = `${process.env.VUE_APP_WZ}/upload/getImages?session=`
export const headerSrcUrl = `${process.env.VUE_APP_WZ}/upload/getImages?session=`
export const videoUrl = `${process.env.VUE_APP_WZ}/upload/downloadVideo?session=`
// export const baseURL = process.env.VUE_APP_GATEWAY
export const httpService = {
    // 获取新闻列表
    getNewsList: (params) => {
        return request.post('/showJournalism/sNewsList', params);
    },
    // 获取首页轮播
    getSwipeList: () => {
        return request.get('/showJournalism/slideshow');
    },
    // 新闻列表详情页
    getNewsDetails: (params) => {
        return request.post(`/publish/getNewDetail/${params}`)
    },
    // 切换灰色背景
    getGray: () => {
        return request.get('/edoPermission/user/getBlackAndWhite')
    },
    // 文件上传
    // 图片上传
    addfile: (params) => {
        let headers = {
            headers: {
                'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq'
            }
        };
        return request.post('/upload/addfile', params, headers);
    },
}