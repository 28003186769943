<template>
    <div>
        <div>
            <NavMenu/>
        </div>
        <div class="centerWarp">
            <img src="@/assets/imgs/train/topimg.png" class="topimg">
            <div class="breadBox">
                <img src="@/assets/imgs/train/icon1.png" class="homeicon" @click="goHomeFn">
                <div class="breadText">当前位置：
                    <span>会议</span>
                </div>
            </div>
            <div class="dataWarp">
                <div class="linebox">会议</div>
                <div class="MeetList">
                    <div v-for="(item,i) in listData" :key="i" class="listItem" @click="goNewsDetail(item)">
                        <img :src="item.img" class="newImg">
                        <div class="type-title ">
                            <!-- <div class="type">【{{item.type}}】</div> -->
                            <div class="title overflowtwo">【{{item.type}}】{{item.title}}</div>
                        </div>
                        <div class="date-source">
                            <div class="date">日期:{{item.date}}</div>
                            <div class="source">来源:{{item.source}}</div>
                        </div>
                    </div>
                </div>
                <div class="pagination-box">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                        :page-sizes="[5,10,20,30]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
        <div class="footerbox">
            <Footer/>
        </div>
    </div>
</template>
<script>
import NavMenu from '@/components/navMenu.vue'
import Footer from '@/components/footer.vue'
import { httpService, srcUrl } from "@/service/httpService";
export default {
    name: "Meeting",
    components: {
        NavMenu,
        Footer,
    },
    data() {
        return {
            srcUrl: srcUrl,
            listData: [
                { img: require("@/assets/imgs/train/img1.png"), title: '第二期人力资源服务赋能方向高级研修班', type: '会议活动', date: '2023-02-12', source: '中国人才交流协会' },
                { img: require("@/assets/imgs/train/img2.png"), title: '第二期人力资源服务赋能方向高级研修班', type: '会议活动', date: '2023-02-12', source: '中国人才交流协会' },
                { img: require("@/assets/imgs/train/img3.png"), title: '第二期人力资源服务赋能方向高级研修班', type: '会议活动', date: '2023-02-12', source: '中国人才交流协会' },
                { img: require("@/assets/imgs/train/img4.png"), title: '第二期人力资源服务赋能方向高级研修班', type: '会议活动', date: '2023-02-12', source: '中国人才交流协会' },
                { img: require("@/assets/imgs/train/img1.png"), title: '第二期人力资源服务赋能方向高级研修班', type: '会议活动', date: '2023-02-12', source: '中国人才交流协会' },
                { img: require("@/assets/imgs/train/img2.png"), title: '第二期人力资源服务赋能方向高级研修班', type: '会议活动', date: '2023-02-12', source: '中国人才交流协会' },
                { img: require("@/assets/imgs/train/img3.png"), title: '第二期人力资源服务赋能方向高级研修班', type: '会议活动', date: '2023-02-12', source: '中国人才交流协会' },
                { img: require("@/assets/imgs/train/img4.png"), title: '第二期人力资源服务赋能方向高级研修班', type: '会议活动', date: '2023-02-12', source: '中国人才交流协会' },
                { img: require("@/assets/imgs/train/img4.png"), title: '第二期人力资源服务赋能方向高级研修班', type: '会议活动', date: '2023-02-12', source: '中国人才交流协会' },
            ],
            currentPage: 1,
            total: 9,
            pageSize: 10,
        }
    },
    mounted() {

    },
    methods: {
        handleSizeChange(val) {
            this.pageSize = val;
            this.currentPage = 1;
        },
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        goNewsDetail(val){
             this.$router.push({
                path: "/news-detail",
                query: {
                    id: val,
                },
            });
        },
        goHomeFn(){
           this.$router.push('/'); 
           sessionStorage.setItem('clickMenu','首页')
        }
    },
    filters: {
        timer: (value) => {
            return value.substring(0, 10);
        },
    },
}
</script>

<style lang="less" scoped>
.centerWarp {
	width: 100%;
	.topimg {
		width: 100%;
		height: 1.6667rem;
	}
	.breadBox {
		width: 100%;
		height: 0.3125rem;
		background: #f6f6f6;
		display: flex;
		flex-direction: row;
		padding-left: 1.4583rem;
		box-sizing: border-box;
		align-items: center;
		.homeicon {
			width: 0.1042rem;
			height: 0.0938rem;
			margin-right: 0.0417rem;
			cursor: pointer;
		}
		.breadText {
			font-size: 0.0833rem;
			font-family: Source Han Sans CN-Regular, Source Han Sans CN;
			font-weight: 400;
			color: #737373;
			// span {
			// 	cursor: pointer;
			// 	&:hover {
			// 		color: #d31114;
			// 	}
			// }
		}
	}
	.dataWarp {
		width: 100%;
		padding: 0.2188rem 1.4583rem 0.2396rem 1.4583rem;
		box-sizing: border-box;
		.linebox {
			font-size: 0.1563rem;
			font-family: Source Han Sans CN-Bold, Source Han Sans CN;
			font-weight: bold;
			color: #333333;
			line-height: 0.1875rem;
			padding-bottom: 0.125rem;
			border-bottom: 0.0052rem solid #d3d3d3;
			position: relative;
			&::before {
				content: '';
				position: absolute;
				width: 0.2708rem;
				height: 0px;
				border: 0.0104rem solid #d31114;
				opacity: 1;
				background: #d31114;
				left: 0;
				transform: translateX(-0%);
				top: 0.2969rem;
			}
		}
		.MeetList {
			// width: 7.0833rem;
			padding-top: 0.2292rem;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
	}
}
</style>