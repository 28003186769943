import axios from 'axios'
import qs from 'qs'
// import { getCookie } from 'tiny-cookie'
import SmartStorage from '../storage/index.js'
import { Loading } from 'element-ui';
import { Message, Dialog } from 'element-ui';

class SmartHttp {
    constructor(options) {
        // 401处理
        this.handle401 = options.handle401 || null;
        // 是否需要加载动画
        // this.disableLoadingAnimation = options.disableLoadingAnimation || false;
        // // 请求动画
        // this.oLoadingAnimation = null;
        // // 请求数量
        // this.nNumberOfRequests = 0;
        // // 请求动画黑名单
        // this.aLoadingBlackList = options.aLoadingBlackList || [];
        // 请求原型
        this.request = axios.create({
            baseURL: options.baseURL || SmartStorage.get('uris').gateway,
            timeout: options.timeout || 30000, // 请求超时时间
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },

        });

        this.injectRequest();
        this.injectResponse();
    }

    // 请求拦截
    injectRequest() {
        this.request.interceptors.request.use(config => {
            const token = SmartStorage.get("token");
            if (token) {
                config.headers["Authorization"] = token;
                // config.responseType = 'blob'; //请求加上responseType
            }


            // //开始动画
            // if (!this.disableLoadingAnimation && this.aLoadingBlackList.indexOf(config.url) === -1) {
            //     this.sLoadingAnimation();
            // }

            return config;
        },
            error => {
                return Promise.reject(error);
            });
    }

    // 响应拦截
    injectResponse() {
        //    let _this=this
        this.request.interceptors.response.use(response => {
            const {
                data,
                status,
                config: { url },
            } = response;

            // // 结束动画
            // if (!this.disableLoadingAnimation && this.aLoadingBlackList.indexOf(url) === -1) {
            //     this.eLoadingAnimation();
            // }
            // 根据 status 状态拦截
            switch (status) {
                case 200:
                    // Todo: 业务状态为false时做处理
                    return data;
                default:
                    return Promise.reject(response);
            }
        },
            error => {
                // console.log(error,'error')
                if (error.response) {
                    const {
                        data,
                        status,
                        data: { data: resData },
                        config: { url },
                    } = error.response;
                    // 根据 status 状态拦截
                    switch (status) {
                        case 400:
                            // 密码错误拦截
                            if (data.LockMinute) {
                                return data;
                            } else {
                                // 其他400拦截（用户不存在）
                                // Message(data.error_description);
                                Message({
                                    message: data.error_description,
                                    type: 'error'
                                })
                            }
                            return Promise.reject(new Error(resData || "请求错误"));
                        case 401:
                            // reLogin();
                            if (this.handle401) {
                                this.handle401();
                                return;
                            }
                            Message({
                                message: '请求未授权',
                                type: 'error'
                            })
                            return Promise.reject(new Error(resData || "请求未授权"));
                        case 403:
                            Message({
                                message: '请求未授权,请先登录网站',
                                type: 'error'
                            })
                            // sessionStorage.removeItem('clickMenu')
                            sessionStorage.removeItem('userInfo')
                            sessionStorage.removeItem('token')
                        
                            setTimeout(() => {
                                window.location.href = "/login";
                            }, 2000)
                            return Promise.reject(new Error(resData || "请求未授权"));
                        case 500:
                            Message({
                                message: '服务器错误',
                                type: 'error'
                            })
                            return Promise.reject(new Error(resData || "服务器错误"));
                        default:
                            return Promise.reject(error);
                    }
                } else {
                    Message({
                        message: '请求超时',
                        type: 'error'
                    })
                }
                // // 结束动画
                // if (!this.disableLoadingAnimation && this.aLoadingBlackList.indexOf(url) === -1) {
                //     this.eLoadingAnimation();
                // }
            });
    }

    /**
     * 开始动画
     */
    sLoadingAnimation() {
        // 如果请求数量等于 0，则开始动画
        if (this.nNumberOfRequests === 0) {
            this.oLoadingAnimation = Loading.service(
                {
                    text: '加载中...'
                }
            );
            // Toast.loading({
            //     duration: 0, // 持续展示 toast
            //     forbidClick: true,
            //     message: '加载中...',
            // });

        }
        // 请求数量++
        this.nNumberOfRequests++;
    }

    /**
     * 结束动画
     */
    eLoadingAnimation() {
        // 请求数量--
        this.nNumberOfRequests--;
        // 如果请求数量小于等于 0，则结束动画
        if (this.nNumberOfRequests <= 0) {
            this.oLoadingAnimation.clear();
        }
    }
}

export default SmartHttp;
