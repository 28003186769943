<template>
    <div class="SearchPage">
        <NavMenu/>
        <img src="@/assets/imgs/train/topimg.png" class="topimg">
        <div class="centerbox">
            <div class="searchBox">
                <el-input placeholder="请输入内容" v-model="searchVal" class="input-with-select" @keyup.enter.native="searchFn">
                    <el-button slot="append" @click="searchFn">搜索</el-button>
                </el-input>
                <div class="searchtext" v-if="dataList.length!=0">为您搜索到
                    <span class="text">{{total}}</span>条关于"
                    <span class="text">{{searchVal}}</span>"的文章
                </div>
                <div v-else class="notText">
                    <div class="text1">抱歉没有找到与
                        <span class="text">"{{searchVal}}"</span>相关的文章
                    </div>
                    <div class="text3">
                        <span class="text2">温馨提示：</span>您可以尝试其它关键词，或检查是否输入有误
                    </div>
                </div>
            </div>
            <div class="searchinfo" v-if="dataList.length!=0">
                <div class="datalistBox">
                    <div v-for="(item,index) in dataList" :key="index" class="searchItemBox" @click="goNewsDetailFn(item)">
                        <div class="leftbox">
                            <div class="day">{{item.day}}</div>
                            <div class="month">{{item.month}}</div>
                        </div>
                        <div class="newsContentbox">
                            <div class="title overflowone">{{item.title}}</div>
                            <div class="datesource-box">
                                <span class="date">日期:{{item.date}}</span>
                                <span>来源:{{item.source}}</span>
                            </div>
                            <div class="content overflowtwo">
                                {{item.content}}
                            </div>
                            <!-- <div class="synopsis overflowthree">{{item.synopsis}}</div>
                                <div class="time">{{item.pressTime | timer}}</div> -->
                        </div>
                    </div>
                </div>
                <div class="pagination-box">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
                        :page-sizes="[5,10,20,30]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
                    </el-pagination>
                </div>
            </div>
            <div class="emptyBox" v-else>
                <!-- <img src="@/assets/imgs/home/emptyimg.png" /> -->
            </div>
        </div>
        <Footer/>
    </div>
</template>
<script>
import NavMenu from '@/components/navMenu.vue'
import Footer from '@/components/footer.vue'
import { httpService, srcUrl } from "@/service/httpService";
export default {
    components: {
        NavMenu,
        Footer
    },
    data() {
        return {
            srcUrl: srcUrl,
            searchVal: '',
            currentPage: 1,
            total: 10,
            pageSize: 10,
            dataList: [
                { day: '12', month: '2023-2', title: '【培训动态】第二期人力资源服务赋能方向高级研修班报名进行中', date: '2023-02-12', source: '中国人才交流协会', content: '伴随着数字时代的到来，数字化信息处理日趋普遍。对于PII（个人可识别身份v信息）处理而言，人们在享受数字化所带来的诸多便利同时，也面临着由PII数字化所带来的风险。信息数字化在企业发展过程中对节约企业成本和达到有效管理起到了积极的作用，另一方面，伴随着全球信息化和网络进程的发展，与此相关的个人隐私保护和信息安全问题也日趋严重。《ISO/IEC 27701，安全技术-扩展的ISO/IEC 27001和ISO/IEC 27002-隐私信息管理要求和指南》的发布，填补了目前隐私信息管理体系的空白，将隐私保护的' },
                { day: '12', month: '2023-2', title: '【培训动态】第二期人力资源服务赋能方向高级研修班报名进行中', date: '2023-02-12', source: '中国人才交流协会', content: '伴随着数字时代的到来，数字化信息处理日趋普遍。对于PII（个人可识别身份v信息）处理而言，人们在享受数字化所带来的诸多便利同时，也面临着由PII数字化所带来的风险。信息数字化在企业发展过程中对节约企业成本和达到有效管理起到了积极的作用，另一方面，伴随着全球信息化和网络进程的发展，与此相关的个人隐私保护和信息安全问题也日趋严重。《ISO/IEC 27701，安全技术-扩展的ISO/IEC 27001和ISO/IEC 27002-隐私信息管理要求和指南》的发布，填补了目前隐私信息管理体系的空白，将隐私保护的' },
                { day: '12', month: '2023-2', title: '【培训动态】第二期人力资源服务赋能方向高级研修班报名进行中', date: '2023-02-12', source: '中国人才交流协会', content: '伴随着数字时代的到来，数字化信息处理日趋普遍。对于PII（个人可识别身份v信息）处理而言，人们在享受数字化所带来的诸多便利同时，也面临着由PII数字化所带来的风险。信息数字化在企业发展过程中对节约企业成本和达到有效管理起到了积极的作用，另一方面，伴随着全球信息化和网络进程的发展，与此相关的个人隐私保护和信息安全问题也日趋严重。《ISO/IEC 27701，安全技术-扩展的ISO/IEC 27001和ISO/IEC 27002-隐私信息管理要求和指南》的发布，填补了目前隐私信息管理体系的空白，将隐私保护的' },
                { day: '12', month: '2023-2', title: '【培训动态】第二期人力资源服务赋能方向高级研修班报名进行中', date: '2023-02-12', source: '中国人才交流协会', content: '伴随着数字时代的到来，数字化信息处理日趋普遍。对于PII（个人可识别身份v信息）处理而言，人们在享受数字化所带来的诸多便利同时，也面临着由PII数字化所带来的风险。信息数字化在企业发展过程中对节约企业成本和达到有效管理起到了积极的作用，另一方面，伴随着全球信息化和网络进程的发展，与此相关的个人隐私保护和信息安全问题也日趋严重。《ISO/IEC 27701，安全技术-扩展的ISO/IEC 27001和ISO/IEC 27002-隐私信息管理要求和指南》的发布，填补了目前隐私信息管理体系的空白，将隐私保护的' },
                { day: '12', month: '2023-2', title: '【培训动态】第二期人力资源服务赋能方向高级研修班报名进行中', date: '2023-02-12', source: '中国人才交流协会', content: '伴随着数字时代的到来，数字化信息处理日趋普遍。对于PII（个人可识别身份v信息）处理而言，人们在享受数字化所带来的诸多便利同时，也面临着由PII数字化所带来的风险。信息数字化在企业发展过程中对节约企业成本和达到有效管理起到了积极的作用，另一方面，伴随着全球信息化和网络进程的发展，与此相关的个人隐私保护和信息安全问题也日趋严重。《ISO/IEC 27701，安全技术-扩展的ISO/IEC 27001和ISO/IEC 27002-隐私信息管理要求和指南》的发布，填补了目前隐私信息管理体系的空白，将隐私保护的' },
                { day: '12', month: '2023-2', title: '【培训动态】第二期人力资源服务赋能方向高级研修班报名进行中', date: '2023-02-12', source: '中国人才交流协会', content: '伴随着数字时代的到来，数字化信息处理日趋普遍。对于PII（个人可识别身份v信息）处理而言，人们在享受数字化所带来的诸多便利同时，也面临着由PII数字化所带来的风险。信息数字化在企业发展过程中对节约企业成本和达到有效管理起到了积极的作用，另一方面，伴随着全球信息化和网络进程的发展，与此相关的个人隐私保护和信息安全问题也日趋严重。《ISO/IEC 27701，安全技术-扩展的ISO/IEC 27001和ISO/IEC 27002-隐私信息管理要求和指南》的发布，填补了目前隐私信息管理体系的空白，将隐私保护的' },
                { day: '12', month: '2023-2', title: '【培训动态】第二期人力资源服务赋能方向高级研修班报名进行中', date: '2023-02-12', source: '中国人才交流协会', content: '伴随着数字时代的到来，数字化信息处理日趋普遍。对于PII（个人可识别身份v信息）处理而言，人们在享受数字化所带来的诸多便利同时，也面临着由PII数字化所带来的风险。信息数字化在企业发展过程中对节约企业成本和达到有效管理起到了积极的作用，另一方面，伴随着全球信息化和网络进程的发展，与此相关的个人隐私保护和信息安全问题也日趋严重。《ISO/IEC 27701，安全技术-扩展的ISO/IEC 27001和ISO/IEC 27002-隐私信息管理要求和指南》的发布，填补了目前隐私信息管理体系的空白，将隐私保护的' },
                { day: '12', month: '2023-2', title: '【培训动态】第二期人力资源服务赋能方向高级研修班报名进行中', date: '2023-02-12', source: '中国人才交流协会', content: '伴随着数字时代的到来，数字化信息处理日趋普遍。对于PII（个人可识别身份v信息）处理而言，人们在享受数字化所带来的诸多便利同时，也面临着由PII数字化所带来的风险。信息数字化在企业发展过程中对节约企业成本和达到有效管理起到了积极的作用，另一方面，伴随着全球信息化和网络进程的发展，与此相关的个人隐私保护和信息安全问题也日趋严重。《ISO/IEC 27701，安全技术-扩展的ISO/IEC 27001和ISO/IEC 27002-隐私信息管理要求和指南》的发布，填补了目前隐私信息管理体系的空白，将隐私保护的' },
                { day: '12', month: '2023-2', title: '【培训动态】第二期人力资源服务赋能方向高级研修班报名进行中', date: '2023-02-12', source: '中国人才交流协会', content: '伴随着数字时代的到来，数字化信息处理日趋普遍。对于PII（个人可识别身份v信息）处理而言，人们在享受数字化所带来的诸多便利同时，也面临着由PII数字化所带来的风险。信息数字化在企业发展过程中对节约企业成本和达到有效管理起到了积极的作用，另一方面，伴随着全球信息化和网络进程的发展，与此相关的个人隐私保护和信息安全问题也日趋严重。《ISO/IEC 27701，安全技术-扩展的ISO/IEC 27001和ISO/IEC 27002-隐私信息管理要求和指南》的发布，填补了目前隐私信息管理体系的空白，将隐私保护的' },
                { day: '12', month: '2023-2', title: '【培训动态】第二期人力资源服务赋能方向高级研修班报名进行中', date: '2023-02-12', source: '中国人才交流协会', content: '伴随着数字时代的到来，数字化信息处理日趋普遍。对于PII（个人可识别身份v信息）处理而言，人们在享受数字化所带来的诸多便利同时，也面临着由PII数字化所带来的风险。信息数字化在企业发展过程中对节约企业成本和达到有效管理起到了积极的作用，另一方面，伴随着全球信息化和网络进程的发展，与此相关的个人隐私保护和信息安全问题也日趋严重。《ISO/IEC 27701，安全技术-扩展的ISO/IEC 27001和ISO/IEC 27002-隐私信息管理要求和指南》的发布，填补了目前隐私信息管理体系的空白，将隐私保护的' }
            ]
        }
    },
    // watch: {
    //     $route: {
    //         handler: function (newval, oldVal) {
    //             if (newval.query.val) {
    //                 this.searchVal = this.$route.query.val
    //                 this.getNews()
    //             }
    //             let currentNew = JSON.parse(sessionStorage.getItem('currentNew'))
    //             if (currentNew) {
    //                 if (currentNew.currentPage) {
    //                     this.currentPage = currentNew.currentPage * 1;
    //                     // this.getNews();
    //                 }
    //                 if (currentNew.site) {
    //                     document.body.scrollTop = currentNew.site
    //                 }
    //             }
    //         },
    //         deep: true,
    //         immediate: true,
    //     },
    // },
    created() {
        // console.log(this.$route.query.val);
        // let val=this.$route.query.val
        // console.log(val,'val');
        this.searchVal = sessionStorage.getItem("searchVal");
        // this.searchVal = this.$route.query.val

    },
    mounted() {
        // this.getNews()
    },
    methods: {
        searchFn() {
            // this.getNews()
        },
        // async getNews() {
        //     let params = {
        //         pageSize: this.pageSize,
        //         pageCurrent: this.currentPage,
        //         findContent: this.searchVal,
        //     };
        //     let data = await httpService.findNewsList(params);
        //     if (data.code == 200) {
        //         this.dataList = data.result.list
        //         this.total = data.result.total;
        //         this.$router.push({ query: {} });
        //     }
        // },
        handleSizeChange(val) {
            // this.pageSize = val;
            // this.currentPage = 1;
            // this.getNews();
        },
        handleCurrentChange(val) {
            // this.currentPage = val;
            // this.getNews();
        },
        // goNewsDetailFn(val) {
        //     let currentNew = {
        //         menuurl: '/search',
        //         currentPage: this.currentPage,
        //         site: window.scrollY,
        //         title: '搜索中心'
        //     }
        //     sessionStorage.setItem('currentNew', JSON.stringify(currentNew))
        //     this.$router.push({
        //         path: "/news-detail",
        //         query: {
        //             id: val.pressId,
        //         },
        //     });
        // }

    },
    // filters: {
    //     timer: (value) => {
    //         return value.substring(0, 7);
    //     },
    //     day: (value) => {
    //         return value.substring(8, 10);
    //     },
    // },
}
</script>
<style lang="less" scoped>
.SearchPage {
	width: 100%;
	margin: 0 auto;
	.topimg {
		width: 100%;
		height: 1.6667rem;
	}
	.centerbox {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 1.4583rem;
		min-height: 1.9792rem;
		box-sizing: border-box;
		margin-bottom: 0.2083rem;
		.searchBox {
			width: 6.25rem;
			margin: 0.2604rem auto 0.1406rem auto;
			/deep/.el-button {
				width: 0.6771rem;
				// background: #448df7;
				background: #d31114;
				height: 0.3125rem;
				font-size: 0.1146rem;
				line-height: 0.1875rem;
				color: #ffffff;
				border-radius: 0 0.0417rem 0.0417rem 0;
				border: none;
			}
			/deep/.el-input__inner {
				height: 0.3125rem;
				line-height: 0.3125rem;
			}
			/deep/.el-input-group__append,
			.el-input-group__prepend {
				border: none;
			}
			.searchtext {
				margin-top: 0.1406rem;
				text-align: center;
				color: #333333;
				font-size: 0.09rem;
				.text {
					color: #d31114;
				}
			}
			.notText {
				margin-top: 0.1406rem;
				text-align: center;
				.text1 {
					font-size: 0.1146rem;
					font-family: Source Han Sans CN-Medium, Source Han Sans CN;
					font-weight: 500;
					color: #333333;
					margin-bottom: 0.0833rem;
					.text {
						color: #d31114;
					}
				}
				.text2 {
					font-size: 0.0729rem;
					font-family: Source Han Sans CN-Light, Source Han Sans CN;
					font-weight: 300;
					color: #999999;
				}
				.text3 {
					font-size: 0.0833rem;
					font-family: Source Han Sans CN-Normal, Source Han Sans CN;
					font-weight: 400;
					color: #333333;
				}
			}
		}
		.datalistBox {
			.searchItemBox {
				display: flex;
				flex-direction: row;
				margin-bottom: 0.2604rem;
				padding-bottom: 0.1563rem;
				border-bottom: 0.0052rem solid #dbdbdb;
				cursor: pointer;
				&:hover {
					border-bottom: 0.0052rem solid #d31114;
					.newsContentbox {
						.title {
							color: #d31114;
						}
					}
				}
				.leftbox {
					width: 0.5313rem;
					margin-right: 0.1979rem;
					.day {
						font-size: 0.3125rem;
						font-family: STLiti-Regular, STLiti;
						font-weight: 400;
						color: #d31114;
						text-align: center;
						line-height: 0.4948rem;
						border: 0.0052rem solid #d3d3d3;
					}
					.month {
						text-align: center;
						height: 0.1667rem;
						background: #d31114;
						line-height: 0.1667rem;
						color: #fff;
						font-family: STLiti-Regular, STLiti;
					}
				}
				.newsContentbox {
					width: 6.2917rem;
					.title {
						font-size: 0.1146rem;
						font-family: Source Han Sans CN-Medium, Source Han Sans CN;
						font-weight: 500;
						color: #333333;
						line-height: 0.1875rem;
					}
					.datesource-box {
						font-size: 0.0729rem;
						font-family: Source Han Sans CN-Light, Source Han Sans CN;
						font-weight: 300;
						color: #333333;
						line-height: 0.1563rem;
						margin-bottom: 0.05rem;
						.date {
							margin-right: 0.4688rem;
						}
					}
				}
				.content {
					font-size: 0.0938rem;
					font-family: Source Han Sans CN-Regular, Source Han Sans CN;
					font-weight: 400;
					color: #666666;
					line-height: 0.1667rem;
				}
			}
		}
	}
}
</style>


