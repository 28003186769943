<template>
	<div class="containBox">
		<div>
			<NavMenu/>
		</div>
		<div class="homeContent">
			<div class="carouselBox">
				<el-carousel trigger="click" :interval='6000'>
					<el-carousel-item v-for="(item,index) in swipeData" :key="index">
						<img :src='srcUrl+item.titleImgUrl' v-if="item.titleImgUrl" class="carouselImg" @click="goNewsDetailFn(item)" />
						<!-- <div class="titleBox ">
								<div class="title overflowone">
									{{item.pressTopic}}
								</div>
							</div> -->
					</el-carousel-item>
					<!-- <el-carousel-item v-for="item in 2" :key="item">
						<img src="@/assets/imgs/home/banner.png" class="carouselImg" />
					</el-carousel-item> -->
				</el-carousel>
			</div>
			<div class="titleWarp">
				<div class="mobileTitle">
					<img class="titleImg" src="@/assets/imgs/mobile/6.png">
					<span class="title">课程价值</span>
				</div>
			</div>
			<div class="mobileImg">
				<img src="../assets/imgs/mobile/1.png" alt="" />
			</div>
			<div class="webEImg">
				<img src="../assets/imgs/home/1.png" alt="" />
			</div>
			<!-- <div class="clientsBox">
				<div class="valListBox">
					<div v-for="(item,i) in curriculumValueData" :key="i" class="itemBox">
						<img :src="item.img" class="img">
						<div class="text">{{item.text}}</div>
					</div>
				</div>
			</div> -->
			<div class="hrsmImg">
				<img src="@/assets/imgs/mobile/5.png" @click="link('https://appbxqvuncv7616.h5.xiaoeknow.com/')" alt="">
			</div>
			<div class="webImg">
				<img src="@/assets/imgs/home/img15.png" @click="link('https://appbxqvuncv7616.h5.xiaoeknow.com/')">
			</div>
			<div class="courseSystemBox">
				<!-- <div class="mobileTitle">
					<img class="titleImg" src="@/assets/imgs/mobile/6.png">
					<span class="title">课程内容</span>
				</div> -->
				<div class="titleWarp">
					<div class="mobileTitle">
						<img class="titleImg" src="@/assets/imgs/mobile/6.png">
						<span class="title">课程内容</span>
					</div>
				</div>
				<div class="courseDataWarp">
					<div class="courseDataBox">
						<div v-for="(item,i) in courseSystemData" :key='i' class="courseItem" @mouseover="overFn(item)" @mouseleave='leave'>
							<!-- :src="item.active?item.img:item.hoverImg"  -->
							<div class="titleBox" v-if="!item.active">
								<img class="iconImg" :src="item.hoverImg">
								<div>{{item.title}}</div>
							</div>
							<ul v-else class="ulBox">
								<li v-for="(e,index) in item.children" :key="index">{{e}}</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="courseDataBoxmobile">
					<div v-for="(item,i) in courseSystemData" :key='i' class="courseItem">
						<div class="titleBox">
							<img class="iconImg" :src="item.hoverImg">
							<div>{{item.title}}</div>
						</div>

					</div>
				</div>

			</div>
			<div class="majorBox">
				<!-- <div class="redBox"> -->
				<!-- <div class="mobileTitle">
					<img class="titleImg" src="@/assets/imgs/mobile/6.png">
					<span class="title">强大师资</span>
				</div> -->
				<div class="titleWarp">
					<div class="mobileTitle">
						<img class="titleImg" src="@/assets/imgs/mobile/6.png">
						<span class="title">强大师资</span>
					</div>
				</div>
				<div class="imgwarp">
					<!-- <div v-for="(item,i) in majorData" :key="i" class="imgItem">
							<img :src="item.img" class="imgs">
							<div class="text">{{item.text}}</div>
						</div> -->
					<div class="mobileMajor">
						<img src="@/assets/imgs/mobile/7.png" alt="">
						<!-- <div class="text"> 本课程经过精心遴选，组建了拥有数十位高级顾问、64位特聘讲师、200多位储备专家的强大师资团队。 -->
						<!-- </div> -->
						<div class="text">
							本项目拥有数十位高级顾问、64位特聘讲师、200多位储备专家。讲师经过精心遴选，来自国内高校或权威机构,优秀企业管理层岗位,行业工作经验丰富，学术理论扎实,兼顾经验和实践，拥有跨界视野。
					</div>
					</div>
					<div class="webMajor">
						<img src="@/assets/imgs/home/2.png" class="leftImg">
						<div class="leftBox">
							<img src="@/assets/imgs/home/3.png" class="numImg">
							<!-- <div class="text"> 本课程经过精心遴选，组建了拥有数十位高级顾问、64位特聘讲师、200多位储备专家的强大师资团队。 -->
							<!-- </div> -->
							<div class="text">本项目拥有数十位高级顾问、64位特聘讲师、200多位储备专家。讲师经过精心遴选，来自国内高校或权威机构,优秀企业管理层岗位,行业工作经验丰富，学术理论扎实,兼顾经验和实践，拥有跨界视野。</div>
						</div>
					</div>
				</div>
				<!-- </div> -->
			</div>
			<div class="organizationBox">
				<!-- <div class="mobileTitle">
					<img class="titleImg" src="@/assets/imgs/mobile/6.png">
					<span class="title">主办机构</span>
				</div> -->
				<div class="titleWarp">
					<div class="mobileTitle">
						<img class="titleImg" src="@/assets/imgs/mobile/6.png">
						<span class="title">主办机构</span>
					</div>
				</div>
				<div class="contentBox">
					<!-- <div class="textBoxs">
						<div class="title">中国人才交流协会</div>
						<div class="pt">是由中国人才交流协会为业内高层管理者量身定制的一套全新行业管理系统课程，旨在多维度启发管理者思维，更好应对本行业快速进化带来的业务挑战，把握先机。</div>
						<div class="title">HRS高管能力提升课程</div>
						<div class="pt">
							根据协会项目大纲全新开发课程，与学员进行互动启发式授课。
						</div>
					</div> -->
					<img src="@/assets/imgs/home/img14.png" class="webimg">
					<img src="@/assets/imgs/mobile/3.png" class="mobileimg">
				</div>
			</div>
			<div class="wayPayBox">
				<div class="tabsBox">
					<div class="moreTitleBox">
						<!-- <div class="tabbox">
							<div v-for="(item,index) in newsTitleData" :key="index" :class="activeNewName==item.title?'activetabNamebox':'tabNamebox'"
							    @click="checknewsTab2(item)">
								<span> {{item.title}}</span>
							</div>
						</div> -->
						<div class="mobileTitle">
							<img class="titleImg" src="@/assets/imgs/mobile/6.png">
							<span class="title">相关资讯</span>
						</div>
						<div class="morebox" @click="gosecondFn(item)">
							查看更多→
						</div>
					</div>
					<div class="webmoreTitle">
						<div class="mobileTitle1">
							<img class="titleImg" src="@/assets/imgs/mobile/6.png">
							<span class="title">相关资讯</span>
						</div>
						<div class="morebox" @click="gosecondFn(item)">
							查看更多→
						</div>
					</div>
					<div class="newsList">
						<!-- @click="goNewsDetail(item)" -->
						<div v-for="(item,i) in listData" :key="i" class="columnlistItem" @click="goNewsDetailFn(item)">
							<img :src="srcUrl+item.titleImgUrl" class="newImg">
							<div class="rightContent">
								<!-- <div class="type-title "> -->
								<!-- <div class="type">【{{item.type}}】</div> -->
								<div class="title">{{item.pressTopic}}</div>
								<!-- </div> -->
								<div class="content overflowtwo">{{item.synopsis}}</div>
								<div class="date-source">
									<div class="date">{{item.pressTime | timer}}</div>
									<!-- <div class="source">来源:{{item.pressSource}}</div> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="ContactUsbox">
				<div class="contactusItem">
					<div class="rightblockbox">
						<div class="iconbox">
							<div class="iconimg1 imgbox"></div>
						</div>
						<div class="leftnonebox">
							<div class="title">联系电话</div>
							<div>15757108919 胡老师</div>
						</div>
					</div>
				</div>
				<div class="contactusItem">
					<div class="rightblockbox">
						<div class="iconbox">
							<div class="iconimg3 imgbox"></div>
						</div>
						<div class="leftnonebox">
							<div class="title">微信客服</div>
							<div> 扫描下方二维码，添加客服</div>
							<img src="@/assets/imgs/home/img16.png" alt="">
						</div>
					</div>
				</div>
				<div class="contactusItem">
					<div class="rightblockbox">
						<div class="iconbox" @click="returnTop">
							<div class="iconimg2 imgbox"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footerbox">
			<Footer/>
		</div>
	</div>
</template>

<script>
import NavMenu from '@/components/navMenu.vue'
import Footer from '@/components/footer.vue'
// import Swipe from '@/components/swipe.vue'
import { httpService, srcUrl } from "@/service/httpService";
import { log } from 'util';
export default {
	name: "Home",
	components: {
		NavMenu,
		Footer,
	},
	data() {
		return {
			srcUrl: srcUrl,
			projectBriefData: [
				{
					content: '人力资源服务行业高管综合能力提升项目（高管能力提升课程），是中国人才交流协会为业内高层管理者量身定制的一套全新行业管理系统课程，旨在多维度启发管理者思维，更好应对本行业快速进化带来的业务挑战，把握先机。 ',
					key: '01'
				},
				{
					content: '本课程体系包含人力资源服务企业管理所需八项核心能力：宏观战略能力、企业战略能力、企业管理能力、经营创新能力、品牌推广能力、市场营销能力、合规风控能力及资本运营能力。课程内容板块通过了行业头部机构专家组的严格评审。',
					key: '02'
				},
				{
					content: '为确保专业性、实用性、启发性和前沿性，课程整合了行业资深管理者经验、专业研究机构理论和跨界领域前沿实践等内容，理论结合行业真实案例。所有讲师经过严格筛选，拥有本行业多年实践和领先方法论，根据协会项目大纲全新开发课程，与学员进行互动启发式授课。',
					key: '03'
				},
				{
					content: '学员完成八项课程后，将获得由中国人才交流协会颁发的培训证书。本项目由中国人才交流协会副秘书长单位四川义度企业管理有限公司执行。',
					key: '04'
				}
			],
			TrainingProcessData: [
				{
					text: '试听和购买', key: '01'
				},
				{
					text: '学习进程', key: '02'
				},
				{
					text: '签发证书', key: '03'
				}
			],
			curriculumValueData: [
				{ 'img': require("@/assets/imgs/home/icon2.png"), 'text': '提升管理水平', key: '01' },
				{ 'img': require("@/assets/imgs/home/icon3.png"), 'text': '提高业务能力', key: '02' },
				{ 'img': require("@/assets/imgs/home/icon4.png"), 'text': '开拓战略视野', key: '03' },
				{ 'img': require("@/assets/imgs/home/icon5.png"), 'text': '提升品牌价值', key: '04' },
				{ 'img': require("@/assets/imgs/home/icon6.png"), 'text': '培养将帅良才', key: '05' },
			],
			courseSystemData: [
				// {
				// 	left: [
				{
					img: require("@/assets/imgs/home/course1.png"),
					hoverImg: require("@/assets/imgs/home/course1-1.png"),
					active: false,
					title: '宏观战略能力',
					children: [
						"国家宏观战略解读", "宏观经济形势分析", "人力资源服务行业相关政策解读", "人力资源服务行业发展趋势预测"
					]
				},
				{
					img: require("@/assets/imgs/home/course2.png"),
					hoverImg: require("@/assets/imgs/home/course1-2.png"),
					active: false,
					title: '企业战略能力',
					children: [
						"战略分析", "战略选择", "战略实施", "国内外人力资源服务行业战略案例"
					]
				},
				{
					img: require("@/assets/imgs/home/course3.png"),
					hoverImg: require("@/assets/imgs/home/course1-3.png"),
					active: false,
					title: '企业管理能力',
					children: [
						"党建管理", "企业制度、标准、流程设计", "项目管理", "人力资源管理", "财务管理", "文化管理"
					]
				},
				{
					img: require("@/assets/imgs/home/course4.png"),
					hoverImg: require("@/assets/imgs/home/course1-4.png"),
					active: false,
					title: '品牌推广能力',
					children: [
						"品牌策略规划", "品牌内容管理", "媒介渠道投放", "客户策略", "品效合一营销活动", "人力资源服务企业品牌实践"
					]
				},


				// ],
				// center: [
				{
					img: require("@/assets/imgs/home/course5.png"),
					hoverImg: require("@/assets/imgs/home/course1-5.png"),
					active: false,
					title: '市场营销能力',
					children: [
						"信息、数据搜集处理", "客户开拓", "客户关系管理", "市场团队管理"
					]
				},
				{
					img: require("@/assets/imgs/home/course6.png"),
					hoverImg: require("@/assets/imgs/home/course1-6.png"),
					active: false,
					title: '经营创新能力',
					children: [
						"商业模式创新", "管理制度创新", "产品创新", "市场营销创新", "技术创新", "企业数字素养"
					]
				},

				// ],
				// right: [

				{
					img: require("@/assets/imgs/home/course7.png"),
					hoverImg: require("@/assets/imgs/home/course1-7.png"),
					active: false,
					title: '合规风控能力',
					children: [
						"人力资源服务行业常用相关法律法规条款解析", "企业内部合规管理", "行业监管政策与行业惯例、商业伦理", "风险认知及法律风险预防管理"
					]
				},
				{
					img: require("@/assets/imgs/home/course8.png"),
					hoverImg: require("@/assets/imgs/home/course1-8.png"),
					active: false,
					title: '资本运营能力',
					children: [
						"资本运营理论", "企业资本运营", "人力资源服务行业资本运营"
					]
				},
				// ]
				// }
			],
			majorData: [
				{
					img: require("@/assets/imgs/home/img10.png"),
					text: '本项目拥有数十位高级顾问、64位特聘讲师、200多位储备专家。'
				},
				{
					img: require("@/assets/imgs/home/img11.png"),
					text: '讲师经过精心遴选，来自国内高校或权威机构,优秀企业管理层岗位。'
				},
				{
					img: require("@/assets/imgs/home/img12.png"),
					text: '行业工作经验丰富，学术理论扎实，兼顾经验和实践，拥有跨界视野。'
				},
			],
			setMealData: [
				{ order: '方式一', introduce: '两门线下课程 + 六门线上课程', price: '7800' },
				{ order: '方式二', introduce: '全部线上视频，时间自由', price: '6800' },
				{ order: '方式三', introduce: '全部线下学习，八天时间', price: '9800' },
			],
			onLineData: [
				"1、企业战略能力", "2、市场营销能力", '3、企业管理能力 ', '4、合规风控能力', '5、经营创新能力', '6、资本运营能力'
			],
			offLineData: [
				"1、宏观战略能力（讲师：邱燕、李天国）", '2、品牌推广能力（讲师：宣宁）'
			],
			activeNewName: '相关资讯',
			newsTitleData: [
				{
					title: "相关资讯",
					linkUrl: "/train",
				},
				// {
				// 	title: "会议",
				// 	linkUrl: "/meet",

				// },
				// {
				// 	title: "咨询",
				// 	linkUrl: "/consult",
				// },
			],
			listData: [],
			swipeData: [],
		};
	},
	mounted() {
		this.getswipeDataFn()
		this.goNewsFn()
	},
	methods: {
		async getswipeDataFn() {
			let data = await httpService.getSwipeList();
			if (data.code == 200) {
				this.swipeData = data.result
			}
		},
		goNewsDetailFn(val) {
			this.$router.push({
				path: "/news-detail",
				query: {
					id: val.pressId,
				},
			});
		},
		async goNewsFn() {
			let params = {
				pageSize: 4,
				pageCurrent: 1,
			};
			let data = await httpService.getNewsList(params);
			if (data.code == 200) {
				this.listData = data.result.list
			}
		},
		// checknewsTab2(val) {
		// 	this.activeNewName = val.title
		// },
		overFn(val) {
			this.courseSystemData.forEach(item => {
				if (item.title == val.title) {
					item.active = true
				} else {
					item.active = false
				}
			})
		},
		leave() {
			this.courseSystemData.forEach(item => {
				item.active = false
			})
		},
		link(val) {
			window.open(val)
		},
		returnTop() {
			let number = window.scrollY;
			let timer = setInterval(() => {
				number -= 200;
				window.scrollTo(0, number);
				if (number <= 0) {
					window.clearInterval(timer);
				}
			}, 10);
		},
		gosecondFn() {
			this.$router.push('/morenews')
		}

	},
	filters: {
		timer: (value) => {
			if (value) {
				return value.substring(0, 10);
			}
		},
	},
};
</script>
<style scoped lang="less">
// web端
@import '../assets/css/webHome.less';
// 移动端
@media screen and (max-width: 600px) {
	@import '../assets/css/mobileHome.less';
}
@media screen and (min-width: 1701px) and (max-width: 1800px) {
	.containBox {
		width: 100%;
		overflow-x: hidden;
		.homeContent {
			position: relative;
			// background: #f8f8f8;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.carouselBox {
				/deep/.el-carousel__container {
					height: 667px;
				}
				width: 100%;
				margin-bottom: 0px;
				.carouselImg {
					width: 100%;
					// height: 700px;
					cursor: pointer;
				}
			}
		}
	}
}
@media screen and (min-width: 1601px) and (max-width: 1700px) {
	.containBox {
		width: 100%;
		overflow-x: hidden;
		.homeContent {
			position: relative;
			// background: #f8f8f8;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.carouselBox {
				/deep/.el-carousel__container {
					height: 600px;
				}
				width: 100%;
				margin-bottom: 0px;
				.carouselImg {
					width: 100%;
					// height: 700px;
					cursor: pointer;
				}
			}
		}
	}
}
@media screen and (min-width: 1401px) and (max-width: 1600px) {
	.containBox {
		width: 100%;
		overflow-x: hidden;
		.homeContent {
			position: relative;
			// background: #f8f8f8;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.carouselBox {
				/deep/.el-carousel__container {
					height: 594px;
				}
				width: 100%;
				margin-bottom: 0px;
				.carouselImg {
					width: 100%;
					// height: 700px;
					cursor: pointer;
				}
			}
		}
	}
}
@media screen and (min-width: 1201px) and (max-width: 1400px) {
	.containBox {
		width: 100%;
		overflow-x: hidden;
		.homeContent {
			position: relative;
			// background: #f8f8f8;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.carouselBox {
				/deep/.el-carousel__container {
					height: 517px;
				}
				width: 100%;
				margin-bottom: 0px;
				.carouselImg {
					width: 100%;
					// height: 700px;
					cursor: pointer;
				}
			}
		}
	}
}
@media screen and (min-width: 1101px) and (max-width: 1200px) {
	.containBox {
		width: 100%;
		overflow-x: hidden;
		.homeContent {
			position: relative;
			// background: #f8f8f8;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.carouselBox {
				/deep/.el-carousel__container {
					height: 438px;
				}
				width: 100%;
				margin-bottom: 0px;
				.carouselImg {
					width: 100%;
					// height: 700px;
					cursor: pointer;
				}
			}
		}
	}
}
@media screen and (min-width: 800px) and (max-width: 1100px) {
	.containBox {
		width: 100%;
		overflow-x: hidden;
		.homeContent {
			position: relative;
			// background: #f8f8f8;
			display: flex;
			flex-direction: column;
			justify-content: center;
			.carouselBox {
				/deep/.el-carousel__container {
					height: 328px;
				}
				width: 100%;
				margin-bottom: 0px;
				.carouselImg {
					width: 100%;
					// height: 700px;
					cursor: pointer;
				}
			}
		}
	}
}
</style>
