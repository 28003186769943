<template>
	<div style="overflow-x: hidden;">
		<NavMenu/>
		<div class="detailWarp">
			<!-- <img src="@/assets/imgs/train/topimg.png" class="topimg"> -->
			<div class="breadBox">
				<img src="@/assets/imgs/train/icon1.png" class="homeicon" @click="goHomeFn">
				<div class="breadText">当前位置：
					<!-- <span>{{currenMenu}}</span>> -->
					相关资讯
				</div>
			</div>
			<div class="newsBox">
				<div class="title">{{newsDetailObj.pressTopic }}</div>
				<div class="date">发布日期：{{newsDetailObj.pressTime | timer}}</div>
				<div class="detailbox">
					<div class="textbox"> <img src="" alt="">
					</div>
					<div v-html="newsDetailObj.content"></div>

					<div class="filebox" v-if="documentList.length!=0">
						附件：
						<div v-for="(item,index) in documentList" :key="index" class="file" @click="downloadDOCfN(item)">
							{{item.name}}
						</div>
					</div>
					<div class="source" v-if="newsDetailObj.pressSource" >来源:{{newsDetailObj.pressSource}}</div>
					<!-- <div class="box1">导语:</div>
					<p>
						伴随着数字时代的到来，数字化信息处理日趋普遍。对于PII（个人可识别身份v信息）处理而言，人们在享受数字化所带来的诸多便利同时，也面临着由PII数字化所带来的风险。信息数字化在企业发展过程中对节约企业成本和达到有效管理起到了积极的作用，另一方面，伴随着全球信息化和网络进程的发展，与此相关的个人隐私保护和信息安全问题也日趋严重。
						《ISO/IEC 27701，安全技术-扩展的ISO/IEC 27001和ISO/IEC 27002-隐私信息管理要求和指南》的发布，填补了目前隐私信息管理体系的空白
					</p>
					<p>
						《ISO/IEC 27701，安全技术-扩展的ISO/IEC 27001和ISO/IEC 27002-隐私信息管理要求和指南》的发布，填补了目前隐私信息管理体系的空白，将隐私保护的原则、理念和方法，融入到信息安全保护体系中，并且对PII控制者和PII处理者进行了较为详细且落地性强的规定，细化了隐私信息管理的要求，给企业在隐私保护和信息安全方面给出了指导建议。作为一个国际通用的隐私信息管理工具，能够有效的协助企业对对隐私风险进行识别、分析、采取措施将风险降到可接受水平并维持该水平，并建立隐私保护体系，从管理与技术等多方面出发，从而使企业满足国内外的监管合规要求。同时，隐私信息管理体系的建设，一定程度上也是企业隐私保护能力的一种体现，能够增强企业与消费者、合作伙伴甚至是监管部门的相互信任。
					</p>
					<p>
						一、隐私保护的重要性被不断强调，ISO/IEC 27701标准也随之出台
					</p>
					<p>
						威胁重重，数据滥用、数据窃取、隐私泄露以及“大数据杀熟”等数据安全问题呈现爆发趋势。在此背景下，全球各个国家纷纷颁布相关法律法规，对数据安全与隐私保护相关问题进行严格的规范与引导。
					</p>
					<p>
						如欧盟保护个人数据的《General Data Protection Regulation》 (GDPR)；美国的 《California Consumer Privacy Act》(CCPA)等。
					</p>
					<p>
						1. GDPR
					</p>
					<p>
						欧盟于2018年5月25日正式实施了《通用数据保护条例》 (《General Data Protection Regulation》,简称《GDPR》)，是一项保护欧盟公民个人隐私和数据的法律，其适用范围包括欧盟成员国境内企业的个人数据、也包括欧盟境外企业处理欧盟公民的个人数据。
					</p>
					<p>
						2. CCPA
					</p>
					<p>
						美国已有多个州先在数据安全与隐私保护进行了立法，其中最著名的要数2018年6月加州通过《加州消费者隐私法案》（ 《California Consumer Privacy Act》, 简称《CCPA》）。
					</p>
					<div class="imgbox">
						<img src="@/assets/imgs/news/img1.png" alt="">
						<img src="@/assets/imgs/news/img2.png" alt="">
						<img src="@/assets/imgs/news/img1.png" alt="">
					</div>
					<div class="box1">学员评价:</div>
					<div class="evaluateBox">
						<div v-for="(item,i) in evaluateData" :key="i" class="evaluateItem">
							<div class="topbox">
								<img :src="item.logo" class="logoimg">
								<div class="namejob">
									<div class="name">{{item.name}}</div>
									<div>{{item.job}}</div>
								</div>
							</div>
							<div class="comment">
								{{item.comment}}
							</div>

						</div>

					</div> -->

				</div>
			</div>
			<div @click="returnPage" class="return-box">返回
				<i class="el-icon-caret-right" />
			</div>
		</div>

		<div class="footerbox">
			<Footer/>
		</div>
	</div>
</template>
<script>
import NavMenu from '@/components/navMenu.vue'
import Footer from '@/components/footer.vue'
import { httpService, srcUrl } from "@/service/httpService";
export default {
	components: {
		NavMenu,
		Footer,
	},
	data() {
		return {
			srcUrl: srcUrl,
			newsDetailObj: {},
			documentList: [],
			currenMenu: sessionStorage.getItem('clickMenu') || '首页',
			evaluateData: [
				{ logo: require("@/assets/imgs/news/logo.png"), name: '黄经理', job: '渠道销售总监', comment: '教学内容很有针对性，教师通过对课本的独到深入的讲解，达到了很好的教学效果，能结合多种教学手段，使学生对知识的掌握更深刻。教学内容重点突出，教学目的十分明确。' },
				{ logo: require("@/assets/imgs/news/logo.png"), name: '黄经理', job: '渠道销售总监', comment: '教学内容很有针对性，教师通过对课本的独到深入的讲解，达到了很好的教学效果，能结合多种教学手段，使学生对知识的掌握更深刻。教学内容重点突出，教学目的十分明确。' },
				{ logo: require("@/assets/imgs/news/logo.png"), name: '黄经理', job: '渠道销售总监', comment: '教学内容很有针对性，教师通过对课本的独到深入的讲解，达到了很好的教学效果，能结合多种教学手段，使学生对知识的掌握更深刻。教学内容重点突出，教学目的十分明确。' },
			]

		}
	},
	mounted() {
		let id = this.$route.query.id || this.$route.params.id;
		this.getnewsdetail(id);

	},
	methods: {
		goHomeFn() {
			this.$router.push('/');
			sessionStorage.setItem('clickMenu', '首页')
		},
		async getnewsdetail(id) {
			// let data
			// if (this.type==1) {
			let data = await httpService.getNewsDetails(id);
			// }else{
			//     data = await httpService.getConferee(id);
			// }
			if (data.code == "200") {
				this.newsDetailObj = data.result;
				document.body.scrollTop = 0;
				document.documentElement.scrollTop = 0;
				if (data.result.downloadAttachment) {
					let doclist = data.result.downloadAttachment.split(",")
					doclist = doclist.map((url, index) => {
						return {
							url: srcUrl + url,
							uid: index,
							name: url,
						}
					})
					this.documentList = doclist
				}
			} else {
				this.$message.error(data.message);
			}
		},
		downloadDOCfN(item) {
			console.log(item, 'item');
			let Docurl = `http:${
				process.env.VUE_APP_WZ
				}${"/upload/download?fileUrl="}${item.name}`;
			console.log(Docurl, 'Docurl');
			let a = document.createElement("a");
			document.body.appendChild(a);
			a.style.display = "none";
			a.setAttribute("download", "");
			a.setAttribute("target", "_self");
			a.setAttribute("href", Docurl);
			a.click();;
		},
		// goFatherMenuFn() {
		//     if (this.fatherlinkUrl) {
		//         this.$router.push(this.fatherlinkUrl);
		//     } else {
		//         this.$router.push('/');
		//     }
		// },
		// goChildMenuFn() {
		//     if (this.childMenu) {
		//         this.$router.push(this.childMenu.linkUrl || this.childMenu.menuurl);
		//     }
		// },
		returnPage() {
			this.$router.go(-1);
		}

	},
	filters: {
		timer: (value) => {
			if (value) {
				return value.substring(0, 10);
			}
		},
	},
}
</script>
<style lang="less" scoped>
.detailWarp {
	width: 100%;
	position: relative;
	overflow-x: hidden;
	.topimg {
		width: 100%;
		height: 320px;
	}
	.breadBox {
		// position: absolute;
		// top: 320px;
		width: 100%;
		height: 60px;
		background: #f6f6f6;
		display: flex;
		flex-direction: row;
		padding-left: 280px;
		box-sizing: border-box;
		align-items: center;
		.homeicon {
			width: 20px;
			height: 18px;
			margin-right: 8px;
			cursor: pointer;
		}
		.breadText {
			font-size: 16px;
			font-family: Source Han Sans CN-Regular, Source Han Sans CN;
			font-weight: 400;
			color: #737373;
			// span {
			// 	cursor: pointer;
			// 	&:hover {
			// 		color: #d31114;
			// 	}
			// }
		}
	}
	.newsBox {
		width: 100%;
		padding: 40px 280px 0 280px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 40px;
		.title {
			font-size: 32px;
			font-family: Source Han Sans CN-Medium, Source Han Sans CN;
			font-weight: 500;
			color: #333333;
			line-height: 48px;
			text-align: center;
			margin-bottom: 22px;
		}
		.date {
			font-size: 14px;
			font-family: Source Han Sans CN-Regular, Source Han Sans CN;
			font-weight: 400;
			color: #a2a3ae;
			line-height: 28px;
			text-align: left;
			border-bottom: 1px solid #d3d3d3;
		}
		.detailbox {
			font-size: 16px;
			width: auto;
			margin: 48px 40px;
			// height: 8.4792rem;
			background: rgba(255, 255, 255, 0);
			padding: 36px;
			border: 4px solid #f2b9ba;
			position: relative;
			box-sizing: border-box;
			.textbox {
				width: 192px;
				height: 27px;
				position: absolute;
				top: -15px;
				left: 50%;
				transform: translateX(-50%);
				background: url('../assets/imgs/newsline.png') no-repeat;
				background-size: 192px 27px;
				display: flex;
				justify-content: center;
			}
			.source {
				font-size: 14px;
				color: #a2a3ae;
				margin-top: 12px;
			}
			// .box1 {
			// 	width: 0.4635rem;
			// 	height: 0.1667rem;
			// 	line-height: 0.1667rem;
			// 	background: #d31114;
			// 	text-align: center;
			// 	color: #fff;
			// 	font-size: 0.0833rem;
			// 	margin-bottom: 0.1875rem;
			// }
			// p {
			// 	font-size: 0.09rem;
			// 	line-height: 0.2083rem;
			// 	font-family: Source Han Sans CN-Regular, Source Han Sans CN;
			// 	font-weight: 400;
			// 	color: #666666;
			// }
			// .imgbox {
			// 	margin-bottom: 0.2708rem;
			// 	img {
			// 		margin-right: 0.15rem;
			// 		width: 1.99rem;
			// 		height: 1.276rem;
			// 		&:last-child {
			// 			margin: 0;
			// 		}
			// 	}
			// }
			// .evaluateBox {
			// 	display: flex;
			// 	flex-direction: row;
			// 	.evaluateItem {
			// 		border-left: 0.0052rem solid #d31114;
			// 		padding-left: 0.0885rem;
			// 		margin-right: 0.2708rem;
			// 		&:last-child {
			// 			margin: 0;
			// 		}
			// 		.topbox {
			// 			display: flex;
			// 			flex-direction: row;
			// 			align-items: center;
			// 			.logoimg {
			// 				width: 0.2135rem;
			// 				height: 0.2135rem;
			// 				margin-right: 0.026rem;
			// 			}
			// 			.namejob {
			// 				font-size: 0.0833rem;
			// 				color: #a2a3ae;
			// 				.name {
			// 					font-size: 0.0938rem;
			// 					font-family: Source Han Sans CN-Regular, Source Han Sans CN;
			// 					font-weight: 400;
			// 					color: #333333;
			// 					line-height: 0.15rem;
			// 				}
			// 			}
			// 		}
			// 		.comment {
			// 			font-size: 0.0833rem;
			// 			font-family: Source Han Sans CN-Regular, Source Han Sans CN;
			// 			font-weight: 400;
			// 			color: #898989;
			// 			line-height: 0.1771rem;
			// 		}
			// 	}
			// }
			min-height: 400px;
			/deep/img {
				max-width: 100%;
			}
			/deep/p {
				max-width: 100%;
				word-wrap: break-word;
				// line-height: 30px;
			}
			.filebox {
				width: 100%;
				margin: 0 auto 40px auto;
				padding: 0 0 0 0px;
				box-sizing: border-box;
				font-size: 16px;
				font-weight: bold;
				line-height: 30px;
				margin-top: 40px;
				.file {
					font-size: 16px;
					font-weight: 400;
					// line-height: 30px;
					color: #666;
					cursor: pointer;
					&:hover {
						color: #1088fe;
					}
				}
			}
		}
	}
	.return-box {
		width: 67%;
		margin: 0 auto;
		text-align: right;
		padding-bottom: 40px;
		// padding-right: 280px;
		border-bottom: 1px dashed #dddddd;
		color: #448df7;
		font-size: 16px;
		cursor: pointer;
	}
}
@media screen and (max-width: 600px) {
	.detailWarp {
		width: 100%;
		position: relative;
		.topimg {
			width: 100%;
			height: 2.4rem;
		}
		.breadBox {
			// position: absolute;
			// top: 2.24rem;
			width: 100%;
			height: 0.8rem;
			background: #f6f6f6;
			display: flex;
			flex-direction: row;
			padding-left: 0.4rem;
			box-sizing: border-box;
			align-items: center;
			.homeicon {
				width: 0.4267rem;
				height: 0.4rem;
				margin-right: 0.2133rem;
				cursor: pointer;
			}
			.breadText {
				font-size: 0.3733rem;
				font-family: Source Han Sans CN-Regular, Source Han Sans CN;
				font-weight: 400;
				color: #737373;
				// span {
				// 	cursor: pointer;
				// 	&:hover {
				// 		color: #d31114;
				// 	}
				// }
			}
		}
		.newsBox {
			width: 100%;
			padding: 0.4rem;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-top: 0.5333rem;
			.title {
				font-size: 0.48rem;
				font-family: Source Han Sans CN-Medium, Source Han Sans CN;
				font-weight: 500;
				color: #333333;
				line-height: 0.8rem;
				text-align: center;
				margin-bottom: 0.5867rem;
			}
			.date {
				font-size: 0.3733rem;
				font-family: Source Han Sans CN-Regular, Source Han Sans CN;
				font-weight: 400;
				color: #a2a3ae;
				line-height: 0.7467rem;
				text-align: left;
				border-bottom: 1px solid #d3d3d3;
			}
			.detailbox {
				font-size: 0.4267rem;
				width: 100%;
				margin: 1rem auto;
				// height: 8.4792rem;
				background: rgba(255, 255, 255, 0);
				padding: 0.4rem;
				border: 0.1067rem solid #f2b9ba;
				position: relative;
				box-sizing: border-box;
				.textbox {
					width: 2.72rem;
					height: 0.4rem;
					position: absolute;
					top: -0.2133rem;
					left: 50%;
					transform: translateX(-50%);
					background: url('../assets/imgs/mobile/8.png') no-repeat;
					background-size: 2.72rem 0.4rem;
					display: flex;
					justify-content: center;
					border: none;
				}
				.source {
					font-size: 0.3733rem;
					color: #a2a3ae;

					margin-top: 0.32rem;
				}

				min-height: 400px;
				/deep/img {
					max-width: 100%;
				}
				/deep/p {
					max-width: 100%;
					word-wrap: break-word;
					// line-height: 30px;
				}
				.filebox {
					width: 100%;
					margin: 0 auto 1.0667rem auto;
					padding: 0 0 0 0px;
					box-sizing: border-box;
					font-size: 0.4267rem;
					font-weight: bold;
					line-height: 0.8rem;
					margin-top: 1.0667rem;
					.file {
						font-size: 0.4267rem;
						font-weight: 400;
						// line-height: 30px;
						color: #666;
						cursor: pointer;
						&:hover {
							color: #1088fe;
						}
					}
				}
			}
		}
		.return-box {
			width: 9.6rem;
			margin: 0 auto;
			text-align: right;
			padding-bottom: 1.0667rem;
			// padding-right: 290px;
			border-bottom: 1px dashed #dddddd;
			color: #448df7;
			font-size: 0.4267rem;
			cursor: pointer;
		}
	}
}
</style>


