import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Train from '../views/Train.vue'
import Consult from '../views/Consult.vue'
import Meeting from '../views/Meeting.vue'
import NewsDetail from '@/views/NewsDetail.vue'
import MoreNews from '@/views/MoreNews.vue'
// 搜索中心
import SearchPage from '@/views/SearchPage'
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/train',
    name: 'Train',
    component: Train
  },
  {
    path: '/meet',
    name: 'Meeting',
    component: Meeting
  },
  {
    path: '/consult',
    name: 'Consult',
    component: Consult
  },
  {
    path: '/search',
    name: 'SearchPage',
    component: SearchPage
  },
  {
    path: '/morenews',
    name: 'MoreNews',
    component: MoreNews
  },
  
  {
    path: '/news-detail',
    name: 'NewsDetail',
    component: NewsDetail
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log(to,from,sessionStorage.getItem('hashId'))
  // || sessionStorage.hashId == '#2'
  if (from.fullPath.includes('productService') || to.meta.done && from.meta.done) {
    next()
  }
  else {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    next()
  }
})

export default router
